import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import Drinks from "./pages/Drinks/Drinks";
import Meals from "./pages/Meals/Meals";
import Desserts from "./pages/Desserts/Desserts";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/drinks",
    element: <Drinks />,
  },
  {
    path: "/meals",
    element: <Meals />,
  },
  {
    path: "/desserts",
    element: <Desserts />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
